/* Modal styles */
.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
}

/* .modal-content {
  position: relative;
  padding: 1rem;
  max-width: 28rem;
  max-height: calc(100% - 1rem);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
} */

/* Modal header */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.modal-close-btn {
  border: none;
  background: none;
  cursor: pointer;
}

.modal-close-icon {
  font-size: 1.5rem;
  color: #666;
}

/* Form styles */
.form-grid {
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.form-item {
  display: block;
}

.form-label {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  color: #333;
}

.form-input,
.form-select,
.form-textarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  outline: none;
}

.form-select {
  appearance: none;
}

.form-submit-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

/* Hide scrollbar in Firefox */
@-moz-document url-prefix() {
  .modal-content {
      scrollbar-width: none;
  }
}

tr > th{
  text-align: left;
}

tr > td{
  text-align: left;
}